.margin-top-05 {
    margin-top: 0.5em !important;
}
.margin-top-10 {
    margin-top: 1em
}

.margin-top-20 {
    margin-top: 2em
}

.margin-top-50 {
    margin-top: 5em
}

.white{ color:#fff;}

body.signin {
    background: url(../img/um-ag-slider2.jpg) top left no-repeat;
    background-size: cover;
    height: 100%
}

body.signin .logo {
    background: url(../img/uag_logo_website_v2_600x63.png) top center no-repeat;
    background-size: contain;
    width: 90%;
    height: 40px
}

.google_signin_btn {
    background: url(../img/btn_google_signin_light_normal_web.png) top left no-repeat;
    width: 191px;
    height: 46px;
    display: block;
    text-indent: -99999px;
    outline: 0;
    margin: 0 auto
}

.adminlogo {
    background: url(../img/uag_logo_website_v2_600x63.png) center center no-repeat;
    background-size: contain;
    width: 90%;
    height: 50px;
    max-width: 300px;
	margin:0 auto;
}
.main-header.gray{ background:#ecf0f5;}

.info-box.info-box-md{     
    margin-bottom: 8px;
    min-height: 50px;
    width: 100%;}
	
.info-box-md .info-box-icon {  font-size: 25px;
    height: 50px;
    line-height: 50px;
    width: 50px;
	}	
	
.info-box-md .info-box-content {
    margin-left: 50px;
}	

#uag_sales_calendar .calendar-day{ padding:0px; width:14.2%; border-right:1px solid #fff; border-bottom:1px solid #fff; cursor:auto;}
#uag_sales_calendar .calendar-day.editable{ cursor:pointer;}
#uag_sales_calendar .day-number { padding:0.5em; font-size:1.5em; text-align:right; height:80px; }
#uag_sales_calendar .day-number a{ color:#fff;}
#uag_sales_calendar .calendar-day.today .day-number{ border:2px solid yellow; opacity:0.7;}

#uag_service_calendar .calendar-day{ padding:0px; width:14.2%; border-right:1px solid #fff; border-bottom:1px solid #fff; cursor:auto;}
#uag_service_calendar .calendar-day.editable{ cursor:pointer;}
#uag_service_calendar .day-number { padding:0.5em; font-size:1.5em; text-align:right; height:80px; }
#uag_service_calendar .day-number a{ color:#fff;}
#uag_service_calendar .calendar-day.today .day-number{ border:2px solid yellow; opacity:0.7;}

#loader_holder{
	position:absolute; top:10px; width:50px; right:-50px; height:50px;
}
#loader_holder.shown{
	right:0px;
}

#loader_holder .loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}



#example1_paginate{ float:right;}
#example1_filter{ float:right;}

.required_error{ border:1px solid #FC0;}


.icon-arrow-left::before {
    content: "";
    -x-system-font: none;
    display: inline-block;
    font-family: FontAwesome;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size: inherit;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    
}
.icon-arrow-right::before {
    content: "";
    -x-system-font: none;
    display: inline-block;
    font-family: FontAwesome;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size: inherit;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
}

table.data-align-center > thead > tr > th { text-align:center;}
table.data-align-center > tbody > tr > td { text-align:center;}

.dataTables_filter{ text-align:right;}
.datepicker.datepicker-dropdown.dropdown-menu{ z-index: 99999 !important;}
.opacityhalf{ 
opacity: 0.50;
filter: alpha(opacity =50); }
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#salesdatepicker{ opacity: 0; background-color: #fff!important; color:#fff!important; border: 0px !important; position:absolute; width:1px !important; display:inline;}
#saleslogdatepicker{  opacity: 0; background-color: #fff!important; color:#fff!important; border: 0px !important; position:absolute; width:1px !important; display:inline; }
#servicedatepicker{  opacity: 0; background-color: #fff!important; color:#fff!important; border: 0px !important; position:absolute; width:1px !important; display:inline; }
.noinput{ border:0px !important; padding-left: 0px !important; padding-right: 0px !important; }

.agcolorpicker{ padding: 10px; height:40px; margin: 0px;}
.agcolorpicker li{ height: 15px; width: 15px; display: block; float:left; margin-right:5px; position: relative; }
/*google color set */
/*.gacol1{ background-color: #5588e5;  } /*blue*/
/*.gacol2{ background-color: #66ae53; } /*green*/
/*.gacol3{ background-color: #f5804f; } /*orange*/
/*.gacol4{ background-color: #a3bef7; } /*light purple*/
/*.gacol5{ background-color: #64d0d7; } /*anil*/
/*.gacol6{ background-color: #8ce0be; } /*lightgreen*/
/*.gacol7{ background-color: #f9d46f; } /*yellow*/
/*.gacol8{ background-color: #f9b982; } /*lightorange*/
/*.gacol9{ background-color: #f59183; } /*pink*/
/*.gacol10{ background-color: #d0463e; } /*red*/
/*.gacol11{ background-color: #d3b4fb; } /*xlight purple*/
/*.gacol12{ background-color: #e0e0e0; } /*gray*/
/*
https://almsaeedstudio.com/themes/AdminLTE/pages/calendar.html
*/

.gacol1{ background-color: #3c8dbc ;  } /*blue*/
.gacol2{ background-color: #00a65a ; } /*green*/
.gacol3{ background-color: #ff851b ; } /*orange*/
.gacol4{ background-color: #605ca8 ; } /*light purple*/
.gacol5{ background-color: #39cccc ; } /*anil*/
.gacol6{ background-color: #01ff70 ; } /*lightgreen*/
.gacol7{ background-color: #f39c12 ; } /*yellow*/
.gacol8{ background-color: #f9b982; } /*lightorange*/
.gacol9{ background-color: #f012be ; } /*pink*/
.gacol10{ background-color: #dd4b39 ; } /*red*/
.gacol11{ background-color: #00c0ef ; } /*xlight purple*/
.gacol12{ background-color: #777777 ; } /*gray*/

    
#custom-nav-colorized .nav-tabs li.active.gacol1{ border-top-color: #3c8dbc;  } /*blue*/
#custom-nav-colorized .nav-tabs li.active.gacol2{ border-top-color: #00a65a ; } /*green*/
#custom-nav-colorized .nav-tabs li.active.gacol3{ border-top-color: #ff851b ; } /*orange*/
#custom-nav-colorized .nav-tabs li.active.gacol4{ border-top-color: #605ca8 ; } /*light purple*/
#custom-nav-colorized .nav-tabs li.active.gacol5{ border-top-color: #39cccc ; } /*anil*/
#custom-nav-colorized .nav-tabs li.active.gacol6{ border-top-color: #01ff70 ; } /*lightgreen*/
#custom-nav-colorized .nav-tabs li.active.gacol7{ border-top-color: #f39c12 ; } /*yellow*/
#custom-nav-colorized .nav-tabs li.active.gacol8{ border-top-color: #f9b982; } /*lightorange*/
#custom-nav-colorized .nav-tabs li.active.gacol9{ border-top-color: #f012be ; } /*pink*/
#custom-nav-colorized .nav-tabs li.active.gacol10{ border-top-color: #dd4b39 ; } /*red*/
#custom-nav-colorized .nav-tabs li.active.gacol11{ border-top-color: #00c0ef ; } /*xlight purple*/
#custom-nav-colorized .nav-tabs li.active.gacol12{ border-top-color: #777777; } /*gray*/


.gacol1 .btn-primary, .gacol2 .btn-primary, .gacol3 .btn-primary, .gacol4 .btn-primary 
, .gacol5 .btn-primary , .gacol6 .btn-primary, .gacol7 .btn-primary, .gacol8 .btn-primary
, .gacol9 .btn-primary, .gacol10 .btn-primary, .gacol11 .btn-primary, .gacol12 .btn-primary { background: transparent!important; border:1px solid #fff !important; }


#custom-nav-colorized .nav-tabs li.active{ background-color: #fff; }
#custom-nav-colorized  .nav-tabs li  a{ color: #fff; }
#custom-nav-colorized  .nav-tabs li.active a{ color: #000; }

.gacol1text{ color: #3c8dbc !important;  } /*blue*/
.gacol2text{ color: #00a65a !important ; } /*green*/
.gacol3text{ color: #ff851b !important ; } /*orange*/
.gacol4text{ color: #605ca8 !important ; } /*light purple*/
.gacol5text{ color: #39cccc !important ; } /*anil*/
.gacol6text{ color: #01ff70 !important ; } /*lightgreen*/
.gacol7text{ color: #f39c12 !important ; } /*yellow*/
.gacol8text{ color: #f9b982 !important; } /*lightorange*/
.gacol9text{ color: #f012be !important ; } /*pink*/
.gacol10text{ color: #dd4b39 !important ; } /*red*/
.gacol11text{ color: #00c0ef !important ; } /*xlight purple*/
.gacol12text{ color: #777777 !important; } /*gray*/



.agcolorpicker li.selected:before , .agcolorpicker li:hover:before {
    cursor:pointer;
    content: "";
    font-family: FontAwesome;
    left:1px;
    position:absolute;
    color:#fff;
     top: -2px;
 }
 
.font10{ font-size:10px;}
.font11{ font-size:11px;}
.font12{ font-size:12px;}
.font20{ font-size:20px;}
.bold600{ font-weight:600;}

 #reportdatepicker{ opacity: 0; background-color: #fff!important; color:#fff!important; border: 0px !important; position:absolute; width:1px !important; display:inline;}
 .nomargin{ margin:0px !important;}
 .nopadding{ padding:0px !important;}
 #hdealershipmulti{ height:auto !important;}
 #hservicelocationmulti{ height:auto !important;}
 #changedealership { cursor:pointer;}
 #changeservicelocation { cursor:pointer;}
 
 
 select#mgrdealershiplist{ height:auto !important; max-width: 600px; margin-bottom: 15px}
 